<template slot-scope="scope">
  <div :class="['table-header', `table-header-${formType}`]">
    {{ title }}
    <el-popover
      v-if="formType === 'input'"
      v-model="visible"
      ref="popover"
      placement="bottom"
      width="200"
      @hide="val = ''"
      trigger="click"
    >
      <el-input
        placeholder="请输入"
        v-model="val"
        class="table-header-optSty"
        @keyup.enter.native="search"
      >
        <i slot="suffix" class="el-input__icon el-icon-search" @click="search"></i>
      </el-input>
      <!-- <i slot="reference" class="el-icon-search" :style="color"></i> -->
      <img slot="reference" src="@/assets/svg/search.svg" alt="" />
    </el-popover>

    <el-popover
      v-if="formType === 'select'"
      v-model="visible"
      ref="popover"
      placement="bottom"
      width="200"
      @hide="val = ''"
      trigger="click"
    >
      <el-select
        v-model="val"
        placeholder="请选择"
        @change="search"
        clearable
        filterable
        class="table-header-optSty"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        ></el-option>
      </el-select>
      <!-- <i slot="reference" class="filter-icon"></i> -->
      <img slot="reference" src="@/assets/svg/filter.svg" alt="" />
    </el-popover>

    <el-popover
      v-if="formType === 'cascader'"
      v-model="visible"
      ref="popover"
      placement="bottom"
      width="200"
      @hide="val = []"
      trigger="click"
    >
      <el-cascader
        class="table-popover-input table-header-optSty"
        v-model="val"
        ref="cascaderMallCatergory"
        :props="data"
        @change="search"
      ></el-cascader>
      <!-- <i slot="reference" class="filter-icon-select" :style="color"></i> -->
      <img slot="reference" src="@/assets/svg/filter.svg" alt="" />
    </el-popover>

    <el-popover
      v-if="formType === 'autocomplete'"
      v-model="visible"
      ref="popover"
      placement="bottom"
      width="300"
      @hide="val = ''"
      trigger="click"
    >
      <el-autocomplete
        class="inline-input table-popover-input table-header-optSty"
        v-model="val"
        :fetch-suggestions="querySearch"
        placeholder="请输入内容"
        :trigger-on-focus="false"
        @select="handleSelect"
      ></el-autocomplete>
      <i slot="reference" class="el-icon-search" :style="color"></i>
    </el-popover>
  </div>
</template>

<script>
export default {
  // 表格如果要为表头配置搜索功能就引入这个组件
  name: 'TableSearchHeader',
  components: {},
  props: {
    // 搜索框的种类 有inut文本输入 select下拉输入 cascader级联选择三种
    formType: {
      type: String,
      default: 'input',
    },
    // 该搜索框需要进行搜索的属性
    property: {
      type: String,
      required: true,
    },
    // 需要显示的表头文字
    title: {
      type: String,
      default: '',
    },
    data: {
      type: Object,
    },
    options: {
      type: Array,
    },
    // 传入(queryString property)，返回promise=>value数组
    fuzzySearch: {
      type: Function,
    },
  },
  data() {
    return {
      val: '',
      visible: false,
    };
  },
  computed: {
    color() {
      return this.visible ? 'color: #00f' : 'color: #667082';
    },
  },
  mounted() {
    // 级联选择返回一个列表作为值
    if (this.formType === 'cascader') {
      this.val = [];
    }
  },
  methods: {
    search(value) {
      this.visible = false;
      // 在什么属性上搜索什么值
      let paramLabel;
      const arr = ['admin_division', 'standard_dept_name', 'type_name'];

      if (arr.includes(this.property)) {
        paramLabel = this.$refs.cascaderMallCatergory.getCheckedNodes()[0].pathLabels;
        // 级联菜单收缩
        this.$refs.cascaderMallCatergory.dropDownVisible = false;
      }
      if (this.formType === 'select') {
        paramLabel = this.options.find(item => {
          return item.value === value;
        }).label;
      }
      if (this.formType === 'input') {
        paramLabel = '';
      }

      this.$emit('search', this.property, this.val, this.formType, paramLabel);
    },
    querySearch(queryString, cb) {
      this.fuzzySearch(queryString, this.property).then(values => {
        cb(values);
      });
      // const restaurants = this.restaurants;
      // const results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // // 调用 callback 返回建议列表的数据
      // cb(results);
    },
    handleSelect(item) {
      console.log(item, this.val, 'sss');
      this.search();
    },
  },
};
</script>
<style lang="scss" scoped>
@import './table-search-header.scss';
</style>
